import {
  Avatar,
  AvatarFallback,
  AvatarImage
} from '@repo/ui/components/Avatar.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'

export const UserAvatar = ({
  src,
  className,
  ...props
}: {
  src?: string | null
} & ComponentProps<typeof Avatar>) => {
  return (
    <Avatar {...props} className={cn('rounded-full', className)}>
      {src ? (
        <AvatarImage src={src} alt="user avatar" className="object-contain" />
      ) : null}
      <AvatarFallback className="bg-gray-200 text-primary/70 rounded-none">
        <Icon name="user" />
      </AvatarFallback>
    </Avatar>
  )
}
